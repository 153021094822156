<template>
  <transition name="fade" appear>
    <div
      :class="[
        $style.wrapper,
        {
          [$style.roundIntroState]: isOngoing && showRoundIntro,
          [$style.gameState]:
            !showGameOverState && isOngoing && !showRoundIntro,
          [$style.gameOverState]: showGameOverState,
        },
      ]"
      :style="themeConfig && { backgroundImage: themeConfig.skyColor }"
    >
      <img
        draggable="false"
        :class="$style.sky"
        :src="getThemeAsset('sky.svg')"
      />
      <img
        draggable="false"
        :class="$style.cloudLeft"
        :src="getThemeAsset('cloud-left.svg')"
      />
      <img
        draggable="false"
        :class="$style.cloudRight"
        :src="getThemeAsset('cloud-right.svg')"
      />
      <img
        draggable="false"
        :class="$style.forest"
        :src="getThemeAsset('forest.svg')"
      />
      <img
        draggable="false"
        :class="$style.lawnBackground"
        :src="getThemeAsset('lawn-background.svg')"
      />
      <img
        draggable="false"
        :class="$style.lawnGrass"
        :src="getThemeAsset('lawn-grass.svg')"
      />
      <img
        draggable="false"
        :class="$style.lawnGrassForeground"
        :src="getThemeAsset('lawn-foreground.svg')"
      />
      <img
        draggable="false"
        :class="$style.treeLeft"
        :src="getThemeAsset('tree.svg')"
      />
      <img
        draggable="false"
        :class="$style.bushLeft"
        :src="getThemeAsset('bush.svg')"
      />
      <img
        draggable="false"
        :class="$style.treeRight"
        :src="getThemeAsset('tree.svg')"
      />
      <img
        draggable="false"
        :class="$style.bushRight"
        :src="getThemeAsset('bush.svg')"
      />
      <div :class="$style.logo" :style="getThemeImageStyle('wood-logo.svg')" />
      <div
        v-if="isOngoing"
        :class="$style.score"
        :style="getThemeImageStyle('wood-score.svg')"
      >
        <ConfettiCoinIcon />
        <span :class="$style.scoreText">{{ coins }}</span>
        <ConfettiTreeIcon :class="$style.treeIcon" />
        <span :class="$style.scoreText">{{ scoreItems }}</span>
      </div>
      <slot />
      <img
        draggable="false"
        :class="$style.grass"
        :src="getThemeAsset('grass.svg')"
      />
      <img
        draggable="false"
        :class="[
          $style.companyLogo,
          { [$style.collapsable]: isCompanyLogoCollapsable },
        ]"
        :src="companyLogo"
        @error="companyLogo = undefined"
      />
      <ConfettiNotice v-if="!isOngoing" :class="$style.footer" />
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ConfettiCoinIcon } from '../components/ConfettiCoinIcon.vue'
import { ConfettiNotice } from '../components/ConfettiNotice.vue'
import { ConfettiTreeIcon } from '../components/ConfettiTreeIcon.vue'
import { useGame } from '../utils/useGame'
import { useTheme } from '../utils/useTheme'

export const ConfettiForestBackground = defineComponent({
  components: {
    ConfettiTreeIcon,
    ConfettiCoinIcon,
    ConfettiNotice,
  },
  setup() {
    const route = useRoute()
    const code = route.params.code as string
    const { game, isGameOngoing, showRoundIntro, isPlayerReady, isGameOver } =
      useGame(code)
    const showGameOverState = computed(
      () => isGameOver.value && isPlayerReady.value,
    )

    const coins = computed(() => game.value?.coins || 0)
    const scoreItems = computed(() => game.value?.scoreItems || 0)

    const companyLogo = ref(`/api/game/${code}/logo`)

    const { getThemeAsset, getThemeImageStyle, themeConfig } = useTheme()

    return {
      isOngoing: computed(() => isGameOngoing.value && isPlayerReady.value),
      isCompanyLogoCollapsable: computed(
        () => isGameOngoing.value && !isGameOver.value,
      ),
      coins,
      scoreItems,
      showRoundIntro,
      showGameOverState,
      companyLogo,
      getThemeAsset,
      getThemeImageStyle,
      themeConfig,
    }
  },
})

export default ConfettiForestBackground
</script>

<style module>
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.background,
.sky {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sky {
  top: 15%;
  object-position: top;
}

.cloudLeft,
.cloudRight {
  position: absolute;
  top: 15%;
  transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.cloudLeft {
  transform: translateY(-50%);
  right: 65%;
}

.cloudRight {
  left: 65%;
}

.forest,
.lawnBackground,
.lawnGrass,
.lawnGrassForeground,
.grass {
  object-position: top;
  width: 100%;
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
  transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.forest {
  transform: translateY(6.5rem);
  max-width: none;
  width: 240rem;
}

.lawnBackground {
  transform: translateY(7.5rem);
  height: 24rem;
}

.lawnGrass {
  transform: translateY(-8rem);
  height: 4rem;
  left: calc(-120rem + 50vw);
  max-width: none;
  width: 240rem;
}

.lawnGrassForeground {
  transform: translateY(6.5rem);
  height: 18.5rem;
}

.grass {
  transform: translateY(6.5rem);
  max-width: none;
  height: 17rem;
  object-fit: cover;
  pointer-events: none;
}

.treeLeft,
.bushLeft,
.treeRight,
.bushRight {
  position: absolute;
  bottom: 0;
  transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.bushLeft,
.bushRight {
  width: 38.5rem;
}

.treeLeft,
.treeRight {
  width: 32rem;
}

.treeLeft,
.bushLeft {
  right: 80%;
}

.treeRight,
.bushRight {
  left: 80%;
}

.companyLogo {
  bottom: 2.5rem;
  left: 2.5rem;
  position: absolute;
  height: 3rem;
  width: auto;
  max-width: 12rem;
}

@media (max-width: 1100px) {
  .companyLogo.collapsable {
    display: none;
  }
}

.logo {
  width: 6rem;
  height: 6rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.score {
  display: flex;
  align-items: center;
  width: 18.5rem;
  height: 8.125rem;
  padding: 2rem 3rem;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  line-height: 3rem;
  font-size: 2rem;
  font-weight: 800;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.scoreText {
  margin: 0.125rem 0.5rem 0;
}

.treeIcon {
  margin-left: auto;
}

.footer {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  z-index: 1;
}

.gameState .cloudLeft {
  right: 70%;
}

.gameState .cloudRight {
  left: 70%;
}

.gameOverState .grass,
.roundIntroState .grass {
  transform: translateY(5rem);
}

.gameOverState .bushLeft,
.gameState .bushLeft,
.roundIntroState .bushLeft {
  transform: translateX(-1rem);
}

.gameOverState .bushRight,
.gameState .bushRight,
.roundIntroState .bushRight {
  transform: translateX(1rem);
}

.gameOverState .treeLeft,
.gameState .treeLeft,
.roundIntroState .treeLeft {
  transform: translate(-2rem, 2rem);
}

.gameOverState .treeRight,
.gameState .treeRight,
.roundIntroState .treeRight {
  transform: translate(2rem, 2rem);
}

.gameState .grass {
  transform: none;
}

.gameState .bushLeft {
  transform-origin: 100% 100%;
  transform: translateX(-1rem) rotateZ(10deg);
}

.gameState .bushRight {
  transform-origin: 0 100%;
  transform: translateX(1rem) rotateZ(-10deg);
}

.gameState .treeLeft {
  transform-origin: 100% 50%;
  transform: translate(-2rem, 2rem) scale(1.2);
}

.gameState .treeRight {
  transform-origin: 0 50%;
  transform: translate(2rem, 2rem) scale(1.2);
}

.gameState .lawnGrassForeground {
  transform: translateY(2rem);
}

.gameState .lawnGrass {
  transform: translateY(-12.25rem);
}

.gameOverState .forest,
.gameOverState .lawnBackground,
.gameOverState .lawnForeground,
.gameState .forest,
.gameState .lawnBackground,
.gameState .lawnForeground {
  transform: none;
}

.gameOverState .lawnGrassForeground {
  transform: none;
}

.gameOverState .lawnForeground {
  transform: translateY(-1rem);
}

.gameOverState .lawnGrass {
  transform: translateY(-14.25rem);
}
</style>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.25s ease;
}
</style>
